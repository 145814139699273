import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'

import MainPage from './pages/MainPage';
import CompanyCampaigns from './pages/CompanyCampaigns';
import Layout from './Layout';
import PackageInfo from './pages/PackageInfo';
import ApplicationsPage from './pages/ApplicationsPage';


function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/:token" element={<MainPage />} />
          <Route path="/:token/:companyID" element={<CompanyCampaigns />} />
          <Route path="/:token/:companyID/:campaignID" element={<PackageInfo />} />
          <Route path="/:token/applications" element={<ApplicationsPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
