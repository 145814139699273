import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';

import { IoAddCircleOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import Services from '../services/Services';
import { CircularProgress } from '@mui/material';


export default function CompanyCampaigns() {
  const { token, companyID } = useParams();
  const location = useLocation();
  const { state } = location;
  const { company } = state;
  const navigate = useNavigate();

  const [companyCampaigns, setCompanyCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleCampaignClick = (campaignID) => {
    console.log(campaignID);
    navigate(`/${token}/${companyID}/${campaignID}`);
  };

  const getCompanyCampaigns = () => {
    Services.GetBolParaCompanyCampaigns(token, companyID)
      .then((res) => {
        if (res.data.status) {
          const campaigns = res.data.result; // Kampanyaları alıyoruz
          const today = Math.floor(Date.now() / 1000); // Bugünün UNIX zaman damgası
  
          // Start ve end tarihleri arasında olan kampanyaları filtrele
          const filteredCampaigns = campaigns.filter(campaign => {
            const startDate = parseInt(campaign.start);
            const endDate = parseInt(campaign.end);
            return today >= startDate && today <= endDate; // Bugün aralıktaysa true döner
          });
  
          setCompanyCampaigns(filteredCampaigns); // Sadece filtrelenenleri set ediyoruz
        } else {
          setCompanyCampaigns([]); // Başarısızsa boş array set ediyoruz
        }
        setIsLoading(false); // Yükleme durumunu false yapıyoruz
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false); // Bir hata olursa da yükleme durumunu false yapıyoruz
      });
  };

  useEffect(() => {
    getCompanyCampaigns()
  }, []);

  return (
    <body>
      <div className='main'>
        {isLoading ?
          <div style={{ marginTop: '35%' }}>
            <CircularProgress />
          </div >
          :
          <>
            <img src={companyCampaigns[0]?.images[0]} />

            <div style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', display: 'flex', alignSelf: 'flex-start' }}>
              <p style={{ color: '#494D59', fontWeight: '700', fontSize: '18px' }}>{company.title} Hakkında Bilgi</p>
            </div>

            <div style={{ alignSelf: 'flex-start' }}>
              <text style={{ color: '#64748B', fontWeight: '400', fontSize: '12px' }}>{company.title} kampanyalarına aşağıdan ulaşabilirsiniz.</text>
            </div>

            {companyCampaigns.map((campaign) => (
              <Button style={{ marginTop: '25px' }} onClick={() => handleCampaignClick(campaign.campaignID)} label={campaign.title} icon={<IoAddCircleOutline color='white' size={24} />} />
            ))}
          </>

        }


      </div>
    </body>
  )
}
